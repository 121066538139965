import React from 'react'
import Layout from '../components/layout'
import ServicesData from '../content/services_data.json'
import Service from '../components/Service'
import licenses from '../assets/images/licenses.jpg'

class Licenses extends React.Component {

	render () {
		return (
			<Layout>
					<Service 
						name={ "Trámites para licencias" } 
						description={ "Los trámites de licencias están directamente relacionados con todas las personas naturales que desean realizar un procedimiento relacionado con su licencia de conducción. En todos los trámites para licencias se debe estar inscrito al RUNT y no tener comparendos." } 
						img={ licenses }
						service_data={ ServicesData.licenses } />
			</Layout>
		)
	}
}

export default Licenses